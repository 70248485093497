<template>
  <div class="container-fluid mt-5">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <template slot="header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("COMMON.ESTABLISHMENTS_DIRECTORY") }}
            </h3>
          </div>
        </div>
      </template>
      <establishment-directory-list-table />
    </card>
  </div>
</template>
<script>
import EstablishmentDirectoryListTable from "./partials/EstablishmentDirectoryListTable.vue";
import { Table, TableColumn, Button, Input } from "element-ui";

export default {
  layout: "DashboardLayout",

  components: {
    EstablishmentDirectoryListTable,
    [Button.name]: Button,
    [Table.name]: Table,
    [Input.name]: Input,
    [TableColumn.name]: TableColumn,
  },

  methods: {},
};
</script>
