<template>
  <div>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div>
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
      </div>

      <el-table
        class="align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="establishments"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>

        <el-table-column
          :label="$t('COMMON.ESTABLISHMENT')"
          prop="id"
          min-width="350px"
        >
          <template v-slot="{ row }">
            <span>
              <strong
                >{{ row.company_name }} ({{
                  $t(
                    "ESTABLISHMENTS.ESTABLISHMENT_TYPE_" +
                      row.establishment_type
                  )
                }})</strong
              >
            </span>
            <br />
            <span>
              {{ row.address }}, {{ row.city }}, {{ $stateName(row.state) }}
              {{ row.zipcode }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('ESTABLISHMENTS.MANAGERS')"
          prop="phone"
          min-width="350px"
        >
          <template v-slot="{ row }">
            <dl class="row">
              <dd class="col-sm-12">
                <div
                  class="row col-12"
                  v-for="manager of row.managers"
                  :key="manager.id"
                >
                  <dl class="row col-12 pl-0 mb-0">
                    <dd class="col-sm-12">
                      {{ `${manager.firstname} ${manager.lastname}` }}
                    </dd>
                  </dl>
                  <dl class="row col-12 pl-0 mb-0" v-if="manager.professio">
                    <dd class="col-sm-12">
                      {{ `${manager.profession}` }}
                    </dd>
                  </dl>
                  <dl class="row col-12 pl-0 mb-0">
                    <dd class="col-sm-12">
                      <a :href="`mailto:${manager.email}`">
                        {{ manager.email }}
                      </a>
                    </dd>
                  </dl>
                  <dl class="row col-12 pl-0" v-if="manager.phone">
                    <dd class="col-sm-12">
                      <span>
                        <phone-number
                          :phoneNumber="manager.phone"
                          :extension="manager.phone_extension"
                          :type="manager.phone_type"
                        /><br />
                      </span>
                      <span
                        v-for="(line, index) in manager.other_phones"
                        :key="index"
                      >
                        <phone-number
                          :phoneNumber="line.phoneNumber"
                          :extension="line.extension"
                          :type="line.type"
                        />
                        <br />
                      </span>
                    </dd>
                  </dl>
                </div>
              </dd>
            </dl>
          </template>
        </el-table-column>

        <el-table-column
          min-width="350px"
          :label="$t('ESTABLISHMENTS.COORDINATOR')"
          prop="tags"
        >
          <template v-slot="{ row }">
            <dl class="row" v-if="row.coordinator">
              <dd class="col-sm-12">
                <dl class="row mb-0">
                  <dd class="col-sm-12">
                    {{
                      `${row.coordinator.firstname} ${row.coordinator.lastname}`
                    }}
                  </dd>
                </dl>
                <dl class="row mb-0" v-if="row.coordinator.profession">
                  <dd class="col-sm-12">
                    {{ `${row.coordinator.profession}` }}
                  </dd>
                </dl>
                <dl class="row mb-0">
                  <dd class="col-sm-12">
                    <a :href="`mailto:${row.coordinator.email}`">
                      {{ row.coordinator.email }}
                    </a>
                  </dd>
                </dl>
                <dl class="row mb-0" v-if="row.coordinator.phone">
                  <dd class="col-sm-12">
                    <span>
                      <phone-number
                        :phoneNumber="row.coordinator.phone"
                        :extension="row.coordinator.phone_extension"
                        :type="row.coordinator.phone_type"
                      />
                      <br />
                    </span>
                    <span
                      v-for="(line, index) in row.coordinator.other_phones"
                      :key="index"
                    >
                      <phone-number
                        :phoneNumber="line.phoneNumber"
                        :extension="line.extension"
                        :type="line.type"
                      />
                      <br />
                    </span>
                  </dd>
                </dl>
              </dd>
            </dl>
          </template>
        </el-table-column>

        <el-table-column :label="$t('COMMON.SERVICECENTER')" min-width="250px">
          <template v-slot="{ row }">
            <span v-if="row.serviceCenter">
              {{ $objectDenomination(row.serviceCenter) }}
            </span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('COMMON.CAFETERIAS')" min-width="250px">
          <template v-slot="{ row }">
            <div
              v-for="cafeteria in row.cafeterias"
              :key="cafeteria.id"
              class="mb-1"
            >
              {{ `${cafeteria.name}` }}<br />
              {{
                cafeteria.service_type
                  ? $t("CAFETERIAS.SERVICE_TYPE_" + cafeteria.service_type)
                  : null
              }}<br />
              {{
                cafeteria.operation_type
                  ? $t("CAFETERIAS.OPERATION_TYPE_" + cafeteria.operation_type)
                  : null
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column min-width="100px" align="center" fixed="right">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_ESTABLISHMENTS)"
            >
              <a
                type="text"
                @click="viewEstablishment(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>

    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>

    <establishment-directory-view-modal
      v-if="!!selectedEstablishment && showViewModal"
      :showModal="showViewModal"
      :establishment="selectedEstablishment"
      @closeModal="closeViewModal"
      @establishmentUpdated="closeViewModal"
    />
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import EstablishmentDirectoryViewModal from "./EstablishmentDirectoryViewModal.vue";

export default {
  name: "establishment-directory-list-table",

  components: {
    BasePagination,
    EstablishmentDirectoryViewModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {},

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      establishments: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      showViewModal: false,
      selectedEstablishment: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          fields: {
            establishments: [
              "establishment_type",
              "company_name",
              "tags",
              "email",
              "phone",
              "phone_extension",
              "phone_type",
              "other_phones",
              "country",
              "state",
              "city",
              "zipcode",
              "address",
              "allowedLocations",
              "tags",
              "managers",
              "cafeterias",
              "serviceCenter",
              "coordinator",
              "created_at",
              "updated_at",
            ].join(","),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include:
            "tags,allowedLocations,coordinator,managers,serviceCenter,cafeterias",
        };
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("establishments/directory", params);
        this.establishments = this.$store.getters["establishments/list"];
        this.total = this.$store.getters["establishments/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewEstablishment(establishment) {
      this.selectedEstablishment = establishment;
      this.showViewModal = true;
    },

    closeViewModal(refresh) {
      this.showViewModal = false;
      this.selectedEstablishment = null;
      if (refresh) {
        this.getList();
      }
    },
  },
};
</script>
