<template>
  <modal
    :show="showModal"
    :modalClasses="'establishment-directory'"
    @close="closeModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <div class="container-fluid">
      <div class="row mb-5">
        <h1>{{ `${establishment.company_name}` }}</h1>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <dl class="row">
            <dt class="col-sm-3">
              {{ $t("COMMON.TAGS") }}
            </dt>
            <dd class="col-sm-9">
              <tags
                :tags="establishment.tags"
                @tagsUpdated="establishmentUpdated"
              />
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-3">{{ $t("COMMON.ADDRESS") }}</dt>
            <dd class="col-sm-9">
              {{ establishment.address }}, {{ establishment.city }},
              {{ $stateName(establishment.state) }}
              {{ establishment.zipcode }}
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-3">{{ $t("COMMON.EMAIL") }}</dt>
            <dd class="col-sm-9">
              {{ establishment.email }}
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-3">
              {{ $t("COMMON.PHONE") }}
            </dt>
            <dd class="col-sm-9">
              <phone-number
                :phoneNumber="establishment.phone"
                :extension="establishment.phone_extension"
                :type="establishment.phone_type"
              />
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              {{ $t("COMMON.OTHER_PHONES") }}
            </dt>
            <dd class="col-sm-9">
              <span
                v-for="(line, index) in establishment.other_phones"
                :key="index"
              >
                <phone-number
                  :phoneNumber="line.phoneNumber"
                  :extension="line.extension"
                  :type="line.type"
                /><br />
              </span>
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-3">{{ $t("COMMON.SERVICECENTER") }}</dt>
            <dd class="col-sm-9">
              {{ $objectDenomination(establishment.serviceCenter) }}
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-3">{{ $t("COMMON.LOCATIONS") }}</dt>
            <dd class="col-sm-9">
              <span
                v-for="(location, idx) in establishment.allowedLocations"
                :key="location.id"
              >
                {{ `${location.name}` }}
                {{
                  establishment.allowedLocations.length - 1 > idx ? "," : " "
                }}
                <br />
              </span>
            </dd>
          </dl>

          <div class="col-12 text-center mb-2 mt-5">
            <h2>{{ $t("ESTABLISHMENTS.ADMINISTRATION") }}</h2>
          </div>

          <dl class="row" v-if="establishment.coordinator">
            <dt class="col-sm-3">{{ $t("ESTABLISHMENTS.COORDINATOR") }}</dt>
            <dd class="col-sm-9">
              <dl class="row mb-0">
                <dd class="col-sm-12">
                  {{
                    `${establishment.coordinator.firstname} ${establishment.coordinator.lastname}`
                  }}
                </dd>
              </dl>
              <dl class="row mb-0" v-if="establishment.coordinator.profession">
                <dd class="col-sm-12">
                  {{ `${establishment.coordinator.profession}` }}
                </dd>
              </dl>
              <dl class="row mb-0">
                <dd class="col-sm-12">
                  <a :href="`mailto:${establishment.coordinator.email}`">
                    {{ establishment.coordinator.email }}
                  </a>
                </dd>
              </dl>
              <dl class="row mb-0" v-if="establishment.coordinator.phone">
                <dd class="col-sm-12">
                  <span>
                    <phone-number
                      :phoneNumber="establishment.coordinator.phone"
                      :extension="establishment.coordinator.phone_extension"
                      :type="establishment.coordinator.phone_type"
                    />
                    <br />
                  </span>
                  <span
                    v-for="(line, index) in establishment.coordinator
                      .other_phones"
                    :key="index"
                  >
                    <phone-number
                      :phoneNumber="line.phoneNumber"
                      :extension="line.extension"
                      :type="line.type"
                    />
                    <br />
                  </span>
                </dd>
              </dl>
            </dd>
          </dl>

          <dl class="row" v-if="establishment.managers.length > 0">
            <dt class="col-sm-3">{{ $t("ESTABLISHMENTS.MANAGERS") }}</dt>
            <dd class="col-sm-9">
              <div
                class="row col-12 pl-0"
                v-for="manager of establishment.managers"
                :key="manager.id"
              >
                <dl class="row col-12 pl-0 mb-0">
                  <dd class="col-sm-12">
                    {{ `${manager.firstname} ${manager.lastname}` }}
                  </dd>
                </dl>
                <dl class="row col-12 pl-0 mb-0" v-if="manager.professio">
                  <dd class="col-sm-12">
                    {{ `${manager.profession}` }}
                  </dd>
                </dl>
                <dl class="row col-12 pl-0 mb-0">
                  <dd class="col-sm-12">
                    <a :href="`mailto:${manager.email}`">
                      {{ manager.email }}
                    </a>
                  </dd>
                </dl>
                <dl class="row col-12 pl-0" v-if="manager.phone">
                  <dd class="col-sm-12">
                    <span>
                      <phone-number
                        :phoneNumber="manager.phone"
                        :extension="manager.phone_extension"
                        :type="manager.phone_type"
                      /><br />
                    </span>
                    <span
                      v-for="(line, index) in manager.other_phones"
                      :key="index"
                    >
                      <phone-number
                        :phoneNumber="line.phoneNumber"
                        :extension="line.extension"
                        :type="line.type"
                      />
                      <br />
                    </span>
                  </dd>
                </dl>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Tags from "@/components/Tags.vue";
import {
  ESTABLISHMENT_TYPE_ELEMENTARY,
  ESTABLISHMENT_TYPE_SECONDARY,
  ESTABLISHMENT_TYPE_COLLEGE,
  ESTABLISHMENT_TYPE_UNIVERSITY,
} from "@/constants/establishments";

export default {
  name: "establishment-view-global",

  components: {
    Tags,
  },

  props: ["establishment", "showModal"],

  data() {
    return {
      ESTABLISHMENT_TYPE_ELEMENTARY: ESTABLISHMENT_TYPE_ELEMENTARY,
      ESTABLISHMENT_TYPE_SECONDARY: ESTABLISHMENT_TYPE_SECONDARY,
      ESTABLISHMENT_TYPE_COLLEGE: ESTABLISHMENT_TYPE_COLLEGE,
      ESTABLISHMENT_TYPE_UNIVERSITY: ESTABLISHMENT_TYPE_UNIVERSITY,
    };
  },

  computed: {},

  methods: {
    establishmentUpdated() {
      this.$emit("establishmentUpdated", true);
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
  },

  watch: {
    establishment(establishment) {},
  },
};
</script>

<style lang="scss">
@media (min-width: 992px) {
  .establishment-directory.modal-lg,
  .establishment-directory.modal-xl {
    max-width: 900px;
  }
}
.establishment-directory.modal-lg .modal-body,
.establishment-directory.modal-xl .modal-body {
  max-height: 90vh;
  overflow-y: auto;
}
</style>
